import { Message, Notification } from "element-ui";
import store from "@/store";

const Success = (msg = '') => {
    Message({
        type: 'success',
        message: msg,
        showClose: true
    })
}

const Error = (msg = '') => {
    Message({
        type: 'error',
        message: msg,
        showClose: true
    })
}

const Warning = (msg = '') => {
    Message({
        type: 'warning',
        message: msg,
        showClose: true
    })
}

const Tip = (code = 200, msg = '', succcessTip = true) => {
    if (code == 200 && succcessTip) {
        Success(msg)
    }
    else {
        Error(msg)
    }
}

const successNotify = (msg = '', title = '') => {
    Notification.success({ title: title, message: msg });
}
const warningNotify = (msg = '', title = '') => {
    Notification.warning({ title: title, message: msg });
}
const errorNotify = (msg = '', title = '') => {
    Notification.error({ title: title, message: msg });
}
const tipNotify = (code = 200, msg = '', title = '', tip = true) => {
    if (code == 200 && tip) {
        Notification.success({ title: title, message: msg });
    }
    else {
        Notification.error({ title: title, message: msg });
    }
}
// 深拷贝
const deepClone = function (obj) {
    // 如果不是对象或是 null 则直接返回
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }
    const newObj = Array.isArray(obj) ? [] : {};
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            newObj[key] = deepClone(obj[key]); // 递归调用深拷贝方法
        }
    }
    return newObj;
}

// 节流
function throttle(func, delay) {
    let lastTime = 0;

    return function () {
        const args = arguments;
        const currentTime = new Date().getTime();

        if (currentTime - lastTime > delay) {
            lastTime = currentTime;
            func.apply(this, args);
        }
    };
}
// 防抖
function debounce(func, delay) {
    let timerId;

    return function () {
        const args = arguments;

        if (timerId) {
            clearTimeout(timerId);
        }

        timerId = setTimeout(() => {
            func.apply(this, args);
        }, delay);
    };
}
function toLocalTime(n) {
    return new Date(parseInt(1000 * n))
        .toLocaleString()
        .replace(/年|月|\//g, "-")
        .replace(/日/g, " ");
}


function cancelRequest() {
    try {
        store.state.cancelTokens.forEach(token => {
            token.cancel()
        })
        store.state.cancelTokens = []
    }catch(e){console.warn("cancelRequest")}
}

// 随机颜色
function randomColor() {
    return '#' + Math.floor(Math.random() * 0xffffff).toString(16)
}


export {
    Success, Error, Warning, Tip, successNotify, errorNotify, warningNotify, tipNotify, throttle, debounce, toLocalTime, cancelRequest,randomColor
}