<template>
    <span class="root" :title="`role:${role}`">
        <el-tag :type="data.color" size="mini">
            <slot>{{ data.name || 'xxx' }}</slot>
        </el-tag>
    </span>
</template>

<script>
import axios from 'axios';
export default {
    name: "Role",
    props: { role: 0 },
    data() {
        return {
            roles: [
                {
                    "role_id": 1,
                    "name": "管理员",
                    "color": "warning"
                },
                {
                    "role_id": 2,
                    "name": "系统管理员",
                    "color": "danger"
                },
                {
                    "role_id": 0,
                    "name": "用户",
                    "color": "primary"
                },
                {
                    "role_id": -1,
                    "name": "测试",
                    "color": "success"
                },
                {
                    "role_id": -2,
                    "name": "代理",
                    "color": "success"
                },
                {
                    "role_id": -3,
                    "name": "封禁用户",
                    "color": "info"
                },
                {
                    "role_id": -5,
                    "name": "访客",
                    "color": "info"
                }
            ],
            data: {
                name: '未知',
                color: 'info'
            }
        }
    },
    methods: {
        get_roles() {
            console.log("本地不存在，从网络读取")
            axios('/api/role/get_roles').then(res => {
                const { code, data } = res.data
                if (code == 200) {
                    this.roles = data.map(item => {
                        return {
                            ...item,
                            role: item.role_id,
                        }
                    });

                    // 保存到vuex
                    this.$store.commit("set_roles", this.roles);
                }
            })
        }
    },
    created() {
        // 从vueX读取roles
        this.roles = this.$store.state.roles;

        if (!this.roles.length) {
            this.get_roles();
        }

        this.roles.forEach(item => {
            if (item.role_id == this.role) {
                this.data = item;
            }
        })
    }
}
</script>

<style lang="less" scoped>
.root {
    cursor: pointer;
    user-select: none;
}
</style>