<template>
    <div>
        <div v-if="tags.length" class="tag-menus" @wheel.prevent="scrollHorizontally">
            <el-tag size="small" closable v-for="(tag, index) in tags" :key="tag.path + index" :route-name="tag.path"
                :disable-transitions="true" @close="closeTag(index)"
                :effect="tag.path == $route.path ? 'dark' : 'plain'" @click="toRoute(tag.path)"
                :type="tag.path == $route.path ? 'primary' : 'info'" @dblclick.native="closeTag(index)">{{
            tag.meta.title
        }}</el-tag>
        </div>
    </div>
</template>

<script>
export default {
    name: 'tag-menu',
    data() {
        return {
            tags: []
        }
    },
    mounted() {
        this.tags = this.$store.state.tagMenus;
        // console.log(this.tags)
    },
    methods: {
        closeTag(index) {
            this.$store.commit('closeTag', index)
        },
        toRoute(path) {
            if (path == this.$route.path) {
                return;
            }
            this.$router.push(path)
        },
        scrollHorizontally(event) {
            event.preventDefault();
            // 阻止冒泡
            event.stopPropagation();
            const delta = Math.max(-1, Math.min(1, (event.deltaY || -event.detail)));
            const scrollContainer = event.currentTarget;
            scrollContainer.scrollLeft += delta * 40; // 将向下滚动转换为向右滚动
        }
    }
};
</script>

<style lang="less" scoped>
.tag-menus {
    max-width: 1000px;
    display: flex;
    // gap: 10px;
    margin-left: 10px;
    overflow-x: scroll;
    overflow-y: hidden;
    // border: 2px solid salmon;
    border-left: 1px solid gainsboro;
    border-right: 1px solid gainsboro;

    // 设置滚动条样式
    &::-webkit-scrollbar {
        width: 10px;
        height: 0px;
    }

    // 滚动方向默认为水平
    &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
    }

    .el-tag {
        border-radius: 0;
    }
}
</style>