import router from "@/router";
import Vue from "vue";
import Vuex from "vuex";
import createPersitedstate from 'vuex-persistedstate'

Vue.use(Vuex);
export default new Vuex.Store({
	state: {
		token: '',
		user: {},
		cancelTokens: [],
		tagMenus: [],
		roles: [],
		globalLoading: false
	},
	getters: {}, // 相当于computed
	mutations: {
		setUser: function (state, user) {
			state.user = user;
		},
		setToken: function (state, token) {
			state.token = token;
		},
		addCancelTokens(state, cancelToken) {
			state.cancelTokens.push(cancelToken);
		},
		setCancelTokens: function (state, cancelTokens) {
			state.cancelTokens = cancelTokens;
			// localStorage.setItem("cancelTokens", cancelTokens);
		},
		addTagMenus(state, tag) {
			const existed = state.tagMenus.some(item => item.path == tag.path);

			if (!existed && tag.path != '/login') {
				state.tagMenus.push(tag);
			}
		},
		closeTag(state, index) {
			state.tagMenus.splice(index, 1);
			if (state.tagMenus.length == 0) {
				try {
					if (state.tagMenus[index].path != '/' && state.tagMenus[index].path != '/index') {
						router.replace("/")
					}
				} catch (e) { }
			}
			else {
				// 返回上一个路由
				router.push(state.tagMenus[state.tagMenus.length - 1].path)
			}
		},
		resetState(state) {
			state.user = {};
			state.token = '';
			state.cancelTokens = [];
			state = {}
			localStorage.clear();
		},
		set_roles(state, roles) {
			state.roles = roles;
		},
		setGlobalLoading(state, globalLoading) {
		    state.globalLoading = globalLoading;
		}
	},
	actions: {},
	modules: {},
	plugins: [
		createPersitedstate({
			key: 'default'
		})
	]
});
