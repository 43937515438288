// 基于角色的元素可见权限控制
import store from "@/store";

export default {
  bind(el, binding, vnode) {
    const { value } = binding;

    if (value && typeof value === 'object') {
      const { allow, ban } = value;
      const role = store.state.user.role;

      if (Array.isArray(allow) && allow.length > 0) {
        // 如果指定了允许的角色数组，检查当前用户的角色是否在其中
        const isVisible = allow.some(item => item === role);
        if (!isVisible) {
          el.style.display = 'none'; // 隐藏元素
        }
      }

      if (Array.isArray(ban) && ban.length > 0) {
        // 如果指定了禁止的角色数组，检查当前用户的角色是否在其中
        const isHidden = ban.some(item => item == role);
        if (isHidden) {
          el.style.display = 'none'; // 隐藏元素
        }
      }
    }
  },
};
