import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import Pagination from "@/components/TablePagination";
import 'default-passive-events';

import globalcss from "./assets/global.less";
import globalcssmobile from "./assets/global_moblie.less";
import common from "./common/index.js";
import Role from "@/components/Role";
import Side from "@/components/Side";
import less from 'less'
Vue.use(less)
Vue.prototype.$c = common;
// 全局组件挂载
Vue.component("TablePagination", Pagination); //自定义分页组件
Vue.component("Role", Role); //自定义角色
Vue.component("Side", Side); //侧边栏


Vue.use(ElementUI);
Vue.config.productionTip = false;

import vSmooth from "./directives/smooth";
Vue.directive('wu-smooth', vSmooth)
import vPermission from './directives/permission';
Vue.directive('permission', vPermission)
import vRoute from './directives/route';
Vue.directive('route', vRoute)


import VConsole from 'vconsole';
if (process.env.NODE_ENV === 'development') {
	const vConsole = new VConsole();
	Vue.use(vConsole)
}
const app = new Vue({
	router,
	store,
	render: (h) => h(App),
});
app.$mount("#app");
