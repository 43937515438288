import { Error } from "@/common/common"
import router from "@/router"

// 注册自定义指令
export default {
    bind(el, binding) {
        if (binding.value == undefined) {
            Error('路由值不能为空！')
            return;
        }
        // 绑定时的逻辑
        el.addEventListener('click', () => {
            if (binding.value.startsWith('http')) {
                // 如果是链接，使用 window.open 打开链接
                window.open(binding.value, '_blank')
            } else {
                // 如果是路由，使用 Vue Router 的编程式导航打开路由
                router.push(binding.value)
            }
        })
    },
    unbind(el) {
        // 解绑时的逻辑
        el.removeEventListener('click', null)
    }
}