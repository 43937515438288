import { render, staticRenderFns } from "./Side.vue?vue&type=template&id=1546be52&scoped=true&"
import script from "./Side.vue?vue&type=script&lang=js&"
export * from "./Side.vue?vue&type=script&lang=js&"
import style0 from "./Side.vue?vue&type=style&index=0&lang=less&"
import style1 from "./Side.vue?vue&type=style&index=1&id=1546be52&lang=less&scoped=true&"
import style2 from "./Side.vue?vue&type=style&index=2&id=1546be52&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1546be52",
  null
  
)

export default component.exports