import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import store from "@/store";
import { Error } from "@/common/common";

Vue.use(VueRouter);

const routes = [
	{
		path: "/login",
		name: "login",
		component: () => import("../views/login.vue"),
		meta: { title: "登录", level: 1, open: true },
	},
	{
		path: "/",
		name: "dashboard",
		component: () => import("../views/dashboard.vue"),
		meta: { title: "仪表盘", role: 1 },
	},
	{
		path: "/index",
		name: "index",
		component: () => import("../views/index.vue"),
		meta: { title: "模板管理", level: 2 },
	},
	{
		path: "/notice",
		name: "notice",
		component: () => import("../views/notice.vue"),
		meta: { title: "公告管理", level: 2 },

	},
	{
		path: "/user",
		name: "user",
		component: () => import("../views/user.vue"),
		meta: { title: "用户管理", role: 1 },
	},
	{
		path: "/update",
		name: "update",
		component: () => import("../views/update.vue"),
		meta: { title: "更新管理", role: 1 },
	},
	{
		path: "/log",
		name: "log",
		component: () => import("../views/log.vue"),
		meta: { title: "系统日志", role: 1 },
	},
	{
		path: "/remote",
		name: "remote",
		component: () => import("../views/remote.vue"),
		meta: { title: "远程管理", role: 1 },
	},
	{
		path: "/variable",
		name: "variable",
		component: () => import("../views/variable.vue"),
		meta: { title: "变量管理", role: 1 },
	},
	{
		path: "/device",
		name: "device",
		component: () => import("../views/device.vue"),
		meta: { title: "设备管理", role: 1 },
	},
	{
		path: "/me",
		name: "me",
		component: () => import("../views/me.vue"),
		meta: { title: "个人信息" },
	},
	{
		path: "/change_pwd",
		name: "change_pwd",
		component: () => import("../views/second/changePwd.vue"),
		meta: { title: "修改密码" },
	},
	{
		path: "/match_record",
		name: "match_record",
		component: () => import("../views/second/match_record.vue"),
		meta: { title: "发布记录", role: 1 },
	},
	{
		path: "/setting",
		name: "setting",
		component: () => import("../views/second/setting.vue"),
		meta: { title: "设置" },
	},
	{
		path: "/test",
		name: "test",
		component: () => import("../views/test.vue"),
		meta: { title: "测试", role: 1 },
	},
	{
		path: "/blacklist",
		name: "blacklist",
		component: () => import("../views/blacklist.vue"),
		meta: { title: "黑名单", role: 1 },
	},
	{
		path: "/markdown",
		name: "markdown",
		component: () => import("../views/second/markdown.vue"),
		meta: { title: "MD编辑器", role: 1 },
	},
	{
		path: "/help",
		name: "help",
		component: () => import("../views/help.vue"),
		meta: { title: "帮助文档" },
	},
	{
		path: "/system_setting",
		name: "system_setting",
		component: () => import("../views/second/system_setting.vue"),
		meta: { title: "系统设置", role: 1 },
	},
	{
		path: "/routers",
		name: "routers",
		component: () => import("../views/routers.vue"),
		meta: { title: "路由管理", role: 1 },
	},
	{
		path: "/role",
		name: "role",
		component: () => import("../views/role.vue"),
		meta: { title: "角色管理", role: 1 },
	},
	{
		path: "/permittion",
		name: "permittion",
		component: () => import("../views/permittion.vue"),
		meta: { title: "权限管理", role: 1 },
	},
	{
		path: "/record",
		name: "record",
		component: () => import("../views/record.vue"),
		meta: { title: "比赛记录", role: 1 },
	},
	{
		path: "/script_update",
		name: "script_update",
		component: () => import("../views/second/scriptUpdate.vue"),
		meta: { title: "脚本更新管理", role: 1 },
	},
	{
		path: "/recycle",
		name: "recycle",
		component: () => import("../views/recycle.vue"),
		meta: { title: "回收站", role: 1 },
	},
	{
		path: "/device_apply",
		name: "device_apply",
		component: () => import("../views/second/device_apply.vue"),
		meta: { title: "设备申请记录", role: 1 },
	},
	{
		path: "/cache",
		name: "cache",
		component: () => import("../views/cache.vue"),
		meta: { title: "缓存管理", role: 1 },
	},
	{
		path: "/price",
		name: "price",
		component: () => import("../views/price.vue"),
		meta: { title: "最新余额" },
	},
	{
		path: "/price_log",
		name: "price_log",
		component: () => import("../views/second/price_log.vue"),
		meta: { title: "余额上报记录管理", role: 1 },
	},
	{
		path: "/template_permittion",
		name: "template_permittion",
		component: () => import("../views/second/template_permittion.vue"),
		meta: { title: "模板权限管理", role: 1 },
	},
	{
		path: "/price_report",
		name: "price_report",
		component: () => import("../views/second/priceReport.vue"),
		meta: { title: "余额报表" },
	},
	{
		path: "/chatgpt",
		name: "chatgpt",
		component: () => import("../views/chatgpt.vue"),
		meta: { title: "chatgpt镜像管理", role: 2 },
	},
	{
		path: "/api_keys",
		name: "api_keys",
		component: () => import("../views/api_keys.vue"),
		meta: { title: "API Key管理", role: 2 },
	},
	{
		path: "/withdrawal_record",
		name: "withdrawal_record",
		component: () => import("../views/withdrawal_record.vue"),
		meta: { title: "提现记录", role: 1 },
	},
	{
		path: "/record_ranking",
		name: "record_ranking",
		component: () => import("../views/record_ranking.vue"),
		meta: { title: "发布量排行", role: 0 },
	},
	{
		path: "/ip",
		name: "ip",
		component: () => import("../views/ip.vue"),
		meta: { title: "IP访问统计", role: 1 },
	},
	{
		path: "/free_record",
		name: "free_record",
		component: () => import("../views/free_record.vue"),
		meta: { title: "免费发布记录", role: 1 },
	},
	{
		path: "*",
		name: "error",
		component: () => import("../views/error/common.vue"),
		meta: { title: "出错啦~", level: 2 },
	},
];

const router = new VueRouter({
	mode: "hash",
	base: process.env.BASE_URL,
	routes,
});

//导航守卫
router.beforeEach((to, from, next) => {
	store.commit('setGlobalLoading', true);
	// 取消请求
	store.state.cancelTokens.forEach(token => {
		if (token && token.cancel && typeof token.cancel == 'function') {
			token.cancel()
			// console.warn("取消请求了")
		}
	})
	store.state.cancelTokens = []

	const token = store.state.token;
	const user = store.state.user;

	if (user.role < to.meta.role) {
		Error("你没有权限访问此页面！");
		return;
	}

	const isLogin = token && user.username;


	if (to.meta.open == true || isLogin) {
		next(); // 开放页面，放行
	}
	else {
		next("/login")
	}
});

// 添加请求头
axios.interceptors.request.use(
	(config) => {
		// 为 Axios 请求添加 token
		const token = store.state.token
		if (token) {
			config.headers.Authorization = token
		}

		// 创建取消token
		config.cancelToken = new axios.CancelToken((cancel) => {
			// 存储请求及其对应的取消函数
			const requestInfo = { cancel, config };
			store.state.cancelTokens.push(requestInfo);
		})
		return config;
	},
	(error) => {
		return Promise.reject(error)
	}
);

axios.interceptors.response.use(
	(response) => {
		// 请求成功后从vuex中删除该请求
		store.state.cancelTokens = store.state.cancelTokens.filter(
			token => token.cancel !== response.config.cancelToken
		);
		return response;
	},
	(error) => {
		if (axios.isCancel(error)) {
			console.log("【请求已取消】");
			return Promise.reject(error)
		}

		let isRedirect = false;
		let redirectUrl = "/";
		if (error && error.response) {
			switch (error.response.status) {
				case 302:
					error.message = "302接口重定向！";
					break;
				case 400:
					error.message = "400请求错误";
					break;
				case 401:
					error.message = "请登录后重试~";
					window.localStorage.clear();
					router.replace("/login");
					break;
				case 403:
					error.message = "403拒绝访问";
					isRedirect = true;
					redirectUrl = "403";
					break;
				case 404:
					error.message = `404请求地址出错: ${error.response.config.url}`;
					break;
				case 408:
					error.message = "408请求超时";
					break;
				case 409:
					error.message = "409系统已存在相同数据！";
					break;
				case 500:
					error.message = "500服务器内部错误";
					break;
				case 501:
					error.message = "501服务未实现";
					break;
				case 502:
					error.message = "502网关错误";
					break;
				case 503:
					error.message = "503服务不可用";
					break;
				case 504:
					error.message = "504网关超时";
					break;
				case 505:
					error.message = "505HTTP版本不受支持";
					break;
				default:
					error.message = "异常问题，请联系管理员！";
					break;
			}

			Error(error.message || "Error");

			if (isRedirect) {
				router.replace(redirectUrl);
			}
		}
		return Promise.reject(error)
	}
);

router.afterEach((to, from, nex) => {
	store.commit('setGlobalLoading', false);
	store.commit('addTagMenus', JSON.parse(JSON.stringify(to))); // 添加路由标签
	document.title = to.meta.title; // 修改标题
	window.scrollTo(0, 0); // 返回顶部
});

export default router;
