<template>
	<div id="app">
		<Side v-if="this.$route.meta.level != 1"></Side>
		<router-view v-else class="router-view"></router-view>
		<div v-if="this.$store.state.globalLoading" class="loading" @click="this.$store.state.globalLoading = false">
			<h1>正在打开(。&gt;︿&lt;)_θ </h1>
			<small>请稍后...</small>
		</div>
	</div>
</template>

<script>
import store from "./store";
export default {
	data() {
		return {
			info: {},
			token: ''
		};
	},
	mounted() {
		this.info = store.state.user;
		this.token = store.state.token;
	}
};
</script>
<style lang="less" scoped>
.router-view {
	transform: translateY(0px);
	box-sizing: border-box;
	height: 100vh;
}

.loading {
	z-index: 99999;
	background-color: rgba(0, 0, 0, 0.3);
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	color: #fff;
}
</style>
