import axios from "axios";
import qs from "qs";
import { Notification, Message } from 'element-ui';
import router from "@/router";
export default {
	test() {
		alert("测试");
	},
	toLocalTime(n) {
		return new Date(parseInt(1000 * n))
			.toLocaleString()
			.replace(/年|月|\//g, "-")
			.replace(/日/g, " ");
	},
	isEmail(str) {
		if (str == null || str == undefined) return false;
		let result =
			str.search(
				/^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/
			) != -1;
		if (result) {
			return str;
		}
		return false;
	},
	goBack() {
		window.location.href = "javascript:history.go(-1)";
	},
	isMobile(ua) {
		return /Android|iPhone|iPad|iPod|BlackBerry|webOS|Windows Phone|SymbianOS|IEMobile|Opera Mini|okhttp\/3.11.0|leisu script/i.test(ua);
	},
	async search_ip_info(ip) {
		return await axios.post("api/common/ip_info", qs.stringify({ ip: ip }))
			.then(res => {
				let msg = res.data.msg;
				if (res.data.code == 200) {
					let info = res.data.data;
					if (res.data.data.country != undefined) {
						msg = info.country + "-" + info.regionName + "-" + info.city + "-" + info.org;
					}
					else {
						msg = info.message
					}
				}
				Notification({
					title: "IP信息",
					type: res.data.code == 200 ? "success" : "error",
					message: msg,
					position: "bottom-right"
				})
				return msg
			})
	},
	//拉黑
	add_blacklist(ip, str) {
		axios.post("/api/blacklist/add_blacklist", qs.stringify({
			type: 0,
			content: ip,
			reason: str
		})).then(res => {
			Message({
				message: res.data.msg,
				type: res.data.code == 200 ? "success" : "error"
			});
		})
	},
	// 防抖
	debounce(fn, delay) {
		let time = null;//time用来控制事件的触发
		return function () {
			if (time !== null) {
				clearTimeout(time);
			}
			time = setTimeout(() => {
				fn.call(this);
				//利用call(),让this的指针从指向window 转成指向
			}, delay)
		}
	},
	timestamp_format(timestamp) {
		if (timestamp < 10000000000) {
			timestamp = timestamp * 1000;
		}
		// 计算当前时间与给定时间戳的时间差
		let diff = Date.now() - timestamp;
		// 如果时间差小于60秒，则输出几秒前
		if (diff < 60000) {
			return Math.floor(diff / 1000) + '秒前';
			// 如果时间差小于3600秒，则输出几分钟前
		} else if (diff < 3600000) {
			return Math.floor(diff / 60000) + '分钟前';
			// 如果时间差小于86400秒，则输出几小时前
		} else if (diff < 86400000) {
			return Math.floor(diff / 3600000) + '小时前';
			// 如果时间差小于604800秒，则输出几天前
		} else if (diff < 604800000) {
			return Math.floor(diff / 86400000) + '天前';
			// 如果时间差大于604800秒，则输出日期
		} else {
			return new Date(timestamp).toLocaleString();
		}
	},

	copy(text) {
		// text是复制文本
		// 创建input元素
		const el = document.createElement('input')
		// 给input元素赋值需要复制的文本
		el.setAttribute('value', text)
		// 将input元素插入页面
		document.body.appendChild(el)
		// 选中input元素的文本
		el.select()
		// 复制内容到剪贴板
		document.execCommand('copy')
		// 删除input元素
		document.body.removeChild(el)
		Message({
			type: "success", message: '复制成功'
		});

	},
	toRoute(path= '/', query= null) {
		router.push({
			path: path,
			query:query
		})
	}
};
